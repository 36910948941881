<template>
  <section v-loading="loading">
    <el-select
      :placeholder="`${$tc('actions.select')} ${$tc('models.course', 2)}`"
      multiple
      v-model="selectedCourses"
      collapse-tags
      @change="handleChange"
      :disabled="disabled"
    >
      <el-option
        v-for="item in courses"
        :key="`course_${item.id}`"
        :label="item.title.filter(t => t.lang === $i18n.locale)[0].value"
        :value="item.id"
      />
    </el-select>
  </section>
</template>

<script>
import { getCourses } from '../../app/departments/api';
export default {
  props: {
    departmentId: {
      type: Number,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true,
      default: true
    }
  },

  data() {
    return {
      courses: [],
      selectedCourses: []
    };
  },

  methods: {
    async getCourses() {
      const id = this.departmentId;
      if (!id) {
        return;
      }
      this.loading = true;
      try {
        const courses = await getCourses(id);
        if (courses && courses.length > 0) {
          this.courses = courses;
        } else {
          this.course = [];
        }
        this.loading = false;
      } catch (err) {
        const error = { message: this.$t('errors.general'), type: 'error' };
        this.$message(error);
        this.loading = false;
      }
    },

    handleChange() {
      this.$emit('input', this.selectedCourses);
    }
  },

  created() {
    this.getCourses();
  }
};
</script>
<style></style>
