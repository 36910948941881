<template>
  <el-tabs type="border-card">
    <el-tab-pane :label="$t('creation.new_user')">
      <ui-card
          v-loading="loading"
          :heading="`${$t('actions.create')} ${$tc('models.user')}`"
      >
        <department-user-form
            :user="user"
            @submit="handleSubmit"
            @cancel="handleCancel"
        ></department-user-form>
      </ui-card>
    </el-tab-pane>
    <el-tab-pane :label="$t('creation.import_users')">
      <ui-card
          v-loading="loading"
          :heading="`${$t('actions.import')} ${$tc('models.users')}`"
      >
        <department-user-import-form
            :user="user"
            @onSaveImport="handleSaveImport"
            @cancel="handleCancel"
        ></department-user-import-form>
      </ui-card>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import UiCard from '@/components/ui/UiCard';
import DepartmentUserForm from '../components/DepartmentUserForm';
import {createUser, createUsers} from '../../users/api';
import {getDepartment} from '../api';
import DepartmentUserImportForm from "../components/DepartmentUserImportForm";

export default {
  data() {
    return {
      user: this.getInitUser(),
      loading: false,
    };
  },

  components: {
    DepartmentUserImportForm,
    UiCard,
    DepartmentUserForm
  },

  methods: {
    async getDepartment() {
      this.loading = true;
      try {
        this.loading = false;
        this.user.department = await getDepartment(
            this.$router.history.current.params.id
        );
      } catch (error) {
        this.loading = false;
        this.$message({message: this.$t('errors.general'), type: 'error'});
      }
    },

    getInitUser() {
      return {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        role: null,
        company: null,
        building: null,
        department: null,
        courses: []
      };
    },

    async handleSubmit() {
      this.loading = true;
      try {
        await createUser(this.user);
        this.loading = false;
        const feedback = {
          message: this.$t('feedback.create_success'),
          type: 'success'
        };
        this.$message(feedback);
        this.$router.back();
      } catch (error) {
        const feedback = {message: this.$t('create_failed'), type: 'error'};
        this.$message(feedback);
        this.loading = false;
      }
    },

    async handleSaveImport(payload) {
      this.loading = true;
      try {
        await createUsers(payload);
        this.loading = false;
        const feedback = {
          message: this.$t('feedback.create_success'),
          type: 'success'
        };
        this.$message(feedback);
        this.$router.back();
      } catch (error) {
        this.loading = false;
        this.$message({ message: this.$t('errors.general'), type: 'error' });
      }
    },

    handleCancel() {
      this.$router.back();
    }
  },

  created() {
    this.getDepartment();
  },

  async mounted() {
    let department = await getDepartment(this.$route.params.id);
    this.$store.commit('changeBreadcrumbs', [
      {name: "models.departments", link: "/app/departments/"},
      {name: department.name, link: "/app/departments/" + department.id},
      {name: "models.users", link: "/app/departments/" + department.id + "/users"},
      {name: "actions.create", link: ""}
    ]);
  },
};
</script>

<style scoped>
.el-tabs--border-card {
  box-shadow: none; /* Disable box shadow */
}
</style>
