<template>
  <ui-list
    :headings="[$t('fields.email'), '']"
    :items="emails"
  >
    <template v-slot:default="props">
      <ui-list-data>
        <el-input v-model="props.item" />
      </ui-list-data>
      <ui-list-data small>
        <ui-link
            type="danger"
            size="small"
            uppercase
            @click="onRemoveItem(props.item)"
        >{{ $t('actions.delete') }}
        </ui-link>
      </ui-list-data>
    </template>
  </ui-list>
</template>

<script>
import UiLink from '@/components/ui/UiLink';
import UiList from '@/components/ui/UiList';
import UiListData from '@/components/ui/UiListData';

export default {
  name: "DepartmentUserCreatorTable",
  props: {
    emails: {
      type: Array,
      required: true
    },
  },

  components: {
    UiLink,
    UiList,
    UiListData
  },

  methods: {
    onRemoveItem(item) {
      this.$emit('onRemoveItem', item);
    }
  }
}
</script>

<style scoped>

</style>